import React, { useState } from 'react'
import { GetServerSideProps } from 'next'
import { ContentfulProvider } from '@pcln/contentful-components'
import { FeatureProvider } from '@pcln/feature-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { HOTEL_PRODUCT_TYPE } from '@/constants/products'
import Layout from '@/components/Layout'
import type { CustomContext, ContentPageProps } from '@/types'
import Hotel from '@/components/Hotels'
import BANNER_TEXT_VALUES from '@/components/AppBanner/constants'
import generateBootstrapData from '@/shared-utils/generate-bootstrap-data'
import mapSetiResponse from '@/shared-utils/map-seti-response'
import TmsScript from '@/components/TmsScript/TmsScript'
import AppBanner from '@/components/AppBanner'
import DEFAULT_META_TAGS from '@/constants/meta'
import Meta from '@/components/Meta'
import Cobrand from '@/components/Cobrand'
import useCanonicalUrl from '@/hooks/useCanonicalUrl'
import usePageTitleForHotels from '@/hooks/usePageTitleForHotels'
import TravelChatBot from '@/components/TravelChatBot'
import getSkymondeData from 'server/utils/get-skymonde-data'
import prePopulateHotelValues from '../../server/prepop/hotels'
import fetchPCLNHeaderFooterContent from '../../server/utils/pcln-header-footer'
import oktaJwtVerify from '../../server/utils/okta-jwt-verifier'
import setiService from '../../server/middleware/seti'
import getContentfulData from '../../server/utils/get-contentful-data'
import retrieveContentfulSecrets from '../../server/utils/retrieve-contentful-secrets'

function Hotels({
  content,
  headerHTML,
  footerHTML,
  bootstrapData
}: ContentPageProps) {
  const pageTitle = usePageTitleForHotels(DEFAULT_META_TAGS.title.hotels)
  const canonicalUrl = useCanonicalUrl()
  const [queryClient] = useState(() => new QueryClient())

  return (
    <>
      <Meta
        description={DEFAULT_META_TAGS.description.hotels}
        pageTitle={pageTitle}
        canonicalUrl={canonicalUrl}
      />
      <TmsScript product="hotels" />
      <FeatureProvider features={bootstrapData.featureToggles}>
        <ContentfulProvider contentful={content}>
          <QueryClientProvider client={queryClient}>
            <AppBanner content={BANNER_TEXT_VALUES[HOTEL_PRODUCT_TYPE]} />
            <Layout headerHTML={headerHTML} footerHTML={footerHTML}>
              <Cobrand />
              <Hotel />
              <TravelChatBot
                enableChatBot
                askPrompt=""
                openPenny=""
                productType="hotel"
                pageName="landing"
              />
            </Layout>
          </QueryClientProvider>
        </ContentfulProvider>
      </FeatureProvider>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<
  ContentPageProps
> = async ctx => {
  const { req, res } = ctx as unknown as CustomContext
  const bootstrapData = generateBootstrapData('hotels', req, res)
  const { webstats, referral, appVersion, locale } = bootstrapData
  const { clientGUID: cguid } = webstats

  const { contentfulDeliverySecret, contentfulPreviewSecret } =
    retrieveContentfulSecrets()
  const isContentfulEnabled =
    res.locals.features?.siteExperience?.isContentfulEnabled ?? true
  const isSkymondeEnabled =
    res.locals.features?.siteExperience?.isSkymondeEnabled ?? false
  const [
    hotelPrePopData,
    pclnHeaderFooterContent,
    signInStatus,
    { content, experimentNames },
    skymondeSEO
  ] = await Promise.all([
    prePopulateHotelValues(ctx.query),
    fetchPCLNHeaderFooterContent(req, res),
    oktaJwtVerify({ authHdr: req.headers.authorization ?? '', cguid }),
    getContentfulData({
      contentTypeId: 'pageLanding',
      contentfulDeliverySecret,
      contentfulPreviewSecret,
      product: 'Hotel',
      contentPreview: ctx.query['content-preview'] === 'true',
      isContentfulEnabled
    }),
    getSkymondeData('hotels', locale.localeCode, isSkymondeEnabled)
  ])

  const setiResponseEvents = await setiService(req, {
    appVersion,
    allowSetiOverrides: true,
    webstats,
    referral,
    reqQueryParams: ctx.query,
    ...(experimentNames && {
      additionalExperiments: experimentNames
    })
  })
  const prePopData = hotelPrePopData ? { hotels: hotelPrePopData } : null

  return {
    props: {
      content,
      ...pclnHeaderFooterContent,
      bootstrapData: {
        ...bootstrapData,
        signInStatus,
        fullSETI: setiResponseEvents,
        seti: mapSetiResponse(setiResponseEvents),
        ...(prePopData && { prePopData }),
        skymondeSEO
      }
    }
  }
}

export default Hotels
